@import './../../../node_modules/bootstrap/scss/bootstrap.scss';

body {
    color: white;
    padding-top: 120px;
    background: #eee;
}

body.raiepsody {
    background: linear-gradient(#003975, #000060);
}

body.darkula {
    background: linear-gradient(#e31b5f, #000);
}

body.darkula-2 {
    background: linear-gradient(#e31b5f, #000);
}

body.la-passagere {
    background: linear-gradient(#788CB6, #FDB813);
}

body.brassin-lutins {
    background: linear-gradient(#52c234, #061700);
}

body.hopzilla {
    background: linear-gradient(#45a247, #283c86);
}

body.donatello {
    background: linear-gradient(#ff4e00, #772F1A);
}

body.berliner-pale-ale {
    background: linear-gradient(#2980B9, #6DD5FA, #FF8C00);
}

body.welcome-to-the-dungeon {
    background: linear-gradient(#9e0200, #000000);
}

body.walk-of-life {
    background: linear-gradient(#f953c6, #ff4360);
}

body.atomic-smash {
    background: linear-gradient(#FFE000, #799F0C);
}

body.funky-kveik {
    background: linear-gradient(#f14a3c, #f7bd47);
}

body.rose-marie {
    background: linear-gradient(#7cbba7, #37534a);
}

body.la-cuenta {
    background: linear-gradient(#603813, #853813);
}

body.la-roussetine {
    background: linear-gradient(#453938, #ee7200);
}

body.dolce-crouta {
    background: linear-gradient(#009246, #bbbbbb, #ce2b37);
}

body.brassinversaire {
    background: linear-gradient(#fc6767, #ec008c);
}

body.raiepsody-2 {
    background: linear-gradient(#003975, #000060);
}

body.welcome-to-the-dungeon-2 {
    background: linear-gradient(to bottom right, #b9090c, #b9090c, #000000, #000000);
}

body.bunix {
    background: #000;
}

body.from-summer-with-love {
    background: linear-gradient(#fc9636, #fe7143, #28c1bf);
}

body.aiguille-verte {
    background: linear-gradient(to bottom right, #fff8ea, #075228);
}

body.allumer-le-feu {
    background: #000;
}

body.gwinizh-du {
    background: #000;
}

body.el-dorado {
    background: linear-gradient(#e1eec3, #f05053);
}

body.sp-95 {
    background: linear-gradient(#291709, #835b3a, #ba8254);
}

body.graw {
    background: linear-gradient(#e1eec3, #f05053);
}

body.brewmance {
    background: linear-gradient(#EF629F, #EECDA3);
}

body.willy-tonka {
    background: linear-gradient(#F37335, #D1913C, #6be585, #dd3e54);
}

body.maree-basse {
    background: linear-gradient(#43C6AC , #191654);
}

body.welcome-to-the-dungeon-3 {
    background: linear-gradient(#9e0200, #000000);
}

body.beer-1003 {
    background: linear-gradient(#355C7D, #6C5B7B, #C06C84);
}

#beer-title {
    @include rfs(2rem, margin);
    @include font-size(5rem);
}

/* Navbar */
.navbar-toggler-icon.scrolled {
    background-image: url("data:image/svg+xml;charset=utf8,%3Csvg viewBox='0 0 32 32' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath stroke='rgb(0, 0, 0)' stroke-width='2' stroke-linecap='round' stroke-miterlimit='10' d='M4 8h24M4 16h24M4 24h24'/%3E%3C/svg%3E")
}

.navbar-toggler-icon.scrolled {
    background-image: url("data:image/svg+xml;charset=utf8,%3Csvg viewBox='0 0 32 32' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath stroke='rgb(255, 255, 255)' stroke-width='2' stroke-linecap='round' stroke-miterlimit='10' d='M4 8h24M4 16h24M4 24h24'/%3E%3C/svg%3E")
}

.nav-item {
    font-size: 1.5em;
    color: white !important;
}

.nav-link {
  color: black;
}

.nav-link.scrolled {
  color: white !important;
}

.fixed-top.scrolled {
  background-color: #212529;
  transition: background-color 200ms linear;
}

.logo-navbar {
  -webkit-filter: invert(100%); /* safari 6.0 - 9.0 */
          filter: invert(100%);
}

/* Dropdown beers */
.dropdown-menu {
    height: auto;
    max-height: 200px;
    overflow-x: hidden;
}

/* Common style */
figure {
	position: relative;
	float: left;
	overflow: hidden;
	text-align: center;
	cursor: pointer;
}

figure img {
	position: relative;
	display: block;
	min-height: 100%;
	max-width: 100%;
	opacity: 0.8;
}

figure figcaption {
	padding: 2em;
	color: #fff;
	text-transform: uppercase;
	font-size: 1.25em;
	-webkit-backface-visibility: hidden;
	backface-visibility: hidden;
}

figure figcaption::before,
figure figcaption::after {
	pointer-events: none;
}

figure figcaption {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
}

/* Anchor will cover the whole item by default */
/* For some effects it will show as a button */

figure h2 {
	font-weight: 300;
}

figure h2 span {
	font-weight: 800;
}

figure h2,
figure a {
	margin: 0;
}

figure a {
	letter-spacing: 1px;
	font-size: 68.5%;
    color: white;
    display: inline-block;
}


/*---------------*/
/***** Ruby *****/
/*---------------*/

figure.effect-ruby img {
	opacity: 1;
	-webkit-transition: opacity 0.50s, -webkit-transform 0.50s;
	transition: opacity 0.50s, transform 0.50s;
	-webkit-transform: scale(1.15);
	transform: scale(1.15);
}

figure.effect-ruby:hover img,
figure.effect-ruby:active img {
	-webkit-transform: scale(1);
	transform: scale(1);
    -webkit-filter: blur(5px);
    filter: blur(5px);
    transition: .4s ease-in-out;
}

figure.effect-ruby h2 {
	margin-top: 20%;
	-webkit-transition: -webkit-transform 0.50s;
	transition: transform 0.50s;
	-webkit-transform: translate3d(0,0,0);
	transform: translate3d(0,0,0);
}

figure.effect-ruby:hover h2,
figure.effect-ruby:active h2 {
    -webkit-transition: -webkit-transform 0.50s;
    transition: transform 0.50s;
    -webkit-transform: translate3d(0,-50px,0);
    transform: translate3d(0,-50px,0);
}

figure.effect-ruby h3 {
    margin: 1em;
    opacity: 0;
    -webkit-transition: opacity 0.50s, -webkit-transform 0.50s;
    transition: opacity 0.50s, transform 0.50s;
    -webkit-transform: translate3d(0,0,0) scale(1.1);
    transform: translate3d(0,0,0) scale(1.1);
}

figure.effect-ruby:hover h3,
figure.effect-ruby:active h3 {
    opacity: 1;
    -webkit-transform: translate3d(0,-50px,0) scale(1);
    transform: translate3d(0,-50px,0) scale(1);
}

figure.effect-ruby a {
	margin: 1em;
	padding: 2em;
	border: 1px solid #fff;
	opacity: 0;
	-webkit-transition: opacity 0.50s, -webkit-transform 0.50s;
	transition: opacity 0.50s, transform 0.50s;
	-webkit-transform: translate3d(0,0,0) scale(1.1);
	transform: translate3d(0,0,0) scale(1.1);
}


figure.effect-ruby:hover a,
figure.effect-ruby:active a {
	opacity: 1;
	-webkit-transform: translate3d(0,-50px,0) scale(1);
	transform: translate3d(0,-50px,0) scale(1);
}

figure.effect-ruby a:hover,
figure.effect-ruby a:active {
	opacity: 1;
    background-color: white;
    color: black;
}


/*-----------------*/
/***** Mobile  *****/
/*-----------------*/


@media only screen and (max-width: 768px) {
    figure.effect-ruby h2 {
        word-spacing: -0.15em;
        font-weight: 500;
    }

    figure.effect-ruby h3 {
        font-size: 0.75em;
    }

    figure.effect-ruby a {
        margin: 0;
        padding: 1em;
    }
}
